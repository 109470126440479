








import Vue from 'vue';
import { registerDownloadSubmodule } from '@/store';
import { CommissionFile, ItemResponse } from '@/types/resource';
import { Download, DownloadAction } from '@/types/state';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    commissionId: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: false,
      default: null,
    },
    fileData: {
      type: Object as () => CommissionFile,
      required: true,
      validator: (o: any) => o.object === 'commission_file',
    },
    onSuccessOpen: Boolean,
  },
  data() {
    return {
      downloadId: null as number | null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      find: 'download/download',
    }),
    dl(): Download | undefined {
      if (!this.downloadId) return undefined;
      return this.find(this.downloadId);
    },
  },
  methods: {
    done() {
      this.loading = false;
    },
    async open(): Promise<void> {
      window.open(await this.dl?.getBlobUrl(), '_blank');
    },
    async download() {
      this.loading = true;

      if (this.dl) {
        await this.open();
        this.done();
        return;
      }

      await registerDownloadSubmodule();

      const action = this.businessId ? 'business/commission/file/Download' : 'commission/file/Download';
      const route = this.businessId ? 'dashboard.commission.file' : 'commission.file';

      const downloadAction: DownloadAction = {
        name: this.fileData.name,
        type: this.fileData.type,
        size: this.fileData.size,
        downloadUrl: this.$store.dispatch(action, {
          BUSINESS_ID: this.businessId,
          COMMISSION_ID: this.commissionId,
          FILE_ID: this.fileData.id,
        }).then((data: ItemResponse<{ url: string }>) => data.data.url),
        onSuccess: () => {
          this.done();
          if (this.onSuccessOpen) this.open();
        },
        onCancel: () => this.done(),
        onError: (err: Error) => {
          this.done();
          this.$et(err);
        },
        routerTo: {
          name: route,
          params: {
            FILE_ID: this.fileData.id,
            COMMISSION_ID: this.commissionId,
            VANITY: this.businessId,
          },
        },
        businessId: this.businessId,
        commissionId: this.commissionId,
      };

      this.downloadId = await this.$store.dispatch('download/download', downloadAction);
    },
  },
});
